
import { defineComponent, ref, onMounted } from 'vue';
import { SlipView, BalanceView } from '@/common/interfaces';
import { ElMessage } from 'element-plus'

import { KIND_MST, PAY_METHOD_MST } from '@/const/masterdata';
import ApiCalls from '@/common/api';
import { accountUtils, DEF_SLIP } from '@/common/accountUtils';

export default defineComponent({
  name: 'SlipInput',
  setup() {
    const api = new ApiCalls();

    const balance = ref<number>(0);
    const submiting = ref<boolean>(false);
    const slip = ref<SlipView>(accountUtils.cloneSlip(DEF_SLIP));
    const balanceloading = ref<boolean>(false);
    const balancedate = ref<string>('');
    const balancelist = ref<BalanceView[]>([]);

    const onsubmit = async () => {
      submiting.value = true;

      const slipData = {
        'tgt_date': accountUtils.getYYYYMMDDStr(slip.value.tgt_date_obj),
        'kind_cd': slip.value.kind_cd,
        'method_cd': slip.value.method_cd,
        'uuid': slip.value.uuid,
        'value': slip.value.value,
        'memo': slip.value.memo ? slip.value.memo : ''
      };

      try {
        await api.postSlip(slipData);
        initData();
        ElMessage({
          showClose: true,
          message: '登録しました',
          type: 'success',
        });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        ElMessage({
          showClose: true,
          message: '登録に失敗しました',
          type: 'error',
        });
      } finally {
        submiting.value = false;
      }
    };
    const initData = async () => {
      // get balance data
      balanceloading.value = true;

      const tgtToDateStr = accountUtils.getYYYYMMDDStr(new Date());
      const balanceList = await api.getBalanceList(tgtToDateStr);
      balancedate.value = accountUtils.getLocalDateStr(tgtToDateStr);
      balancelist.value = balanceList;
      balanceloading.value = false;

      slip.value = accountUtils.cloneSlip(DEF_SLIP);
    };

    onMounted(() => {
      initData();
    });
    return {
      KIND_MST,
      PAY_METHOD_MST,
      //
      balance,
      submiting,
      slip,
      balanceloading,
      balancedate,
      balancelist,
      //
      onsubmit
    }
  }
});
