
import { defineComponent, computed, ref, getCurrentInstance, onMounted, onBeforeUnmount } from 'vue';
import { SlipView } from '@/common/interfaces';
import SlipInputDlg from '@/components/SlipInputDlg.vue';
import { accountUtils, DEF_SLIP } from '@/common/accountUtils';
import ApiCalls from '@/common/api';

const LOAD_DATE_CNT = 3
const LOAD_LIMIT_DATE = 31
const LOAD_LIMIT_REC = 100

export default defineComponent({
  name: 'SlipList',
  setup() {
    const instance = getCurrentInstance();
    const api = new ApiCalls();
    //
    const tgtdate = ref<Date>(new Date());
    const slipViewList = ref<SlipView[]>([]);
    const loading = ref<boolean>(false);
    const loadDateCnt = ref<number>(LOAD_DATE_CNT);
    const loadedDateCnt = ref<number>(0);
    const dlgEditIdx = ref<number>(-1);
    const wkdate = ref<Date>(new Date());
    //
    const dialogSlip = ref<SlipView>(DEF_SLIP);
    const dialogVisible = ref<boolean>(false);
    const listViewStyle = ref<Record<string, string>>({});

    const count = computed(() => {
      return slipViewList.value.length;
    });
    const noMore = computed(() => {
      return slipViewList.value.length >= LOAD_LIMIT_REC || loadedDateCnt.value >= LOAD_LIMIT_DATE;
    });
    const isDisabled = computed(() => {
      return loading.value || noMore.value;
    });

    const slipedit = (uuid: string) => {
      for (const [index, slipView] of slipViewList.value.entries()) {
        if (slipView.uuid == uuid) {
          dialogSlip.value = slipView;
          dialogVisible.value = true;
          dlgEditIdx.value = index;
          return;
        }
      }
    };
    const onSlipSubmit = (mode: string, newSlipData: {[key: string]: string}) => {
      if (mode === 'upd') {
        slipViewList.value.forEach((slipView, index) => {
          if (slipView.uuid == newSlipData.uuid) {
            slipViewList.value[index] = accountUtils.convertMapToSlipView(newSlipData);
            return;
          }
        });
        if (instance != null && instance.proxy != null) instance.proxy.$forceUpdate();
      } else {
        slipViewList.value.splice(dlgEditIdx.value, 1);
      }
      dialogVisible.value = false;
    };
    const onSlipCancel = () => {
      dialogVisible.value = false;
    };
    const loadMore = () => {
      if (!loading.value) {
        loadsub();
      }
    };
    const loadsub = async () => {
      loading.value = true;
      const tgtToDateStr = accountUtils.getYYYYMMDDStr(wkdate.value);
      wkdate.value.setDate(wkdate.value.getDate() + 1 - LOAD_DATE_CNT);
      const tgtFromDateStr = accountUtils.getYYYYMMDDStr(wkdate.value);

      const newSlipViewList = await api.getSlipViewList(tgtToDateStr, tgtFromDateStr);
      Array.prototype.push.apply(slipViewList.value, newSlipViewList);
      wkdate.value.setDate(wkdate.value.getDate() - 1);
      loading.value = false;
      loadedDateCnt.value += loadDateCnt.value;
    };
    const reload = () => {
      if (!loading.value) {
        loading.value = true;
        wkdate.value = new Date(tgtdate.value);
        slipViewList.value = [];
        loadedDateCnt.value = 0;
        loading.value = false;
        loadMore();
      }
    };
    const calcOnResize = () => {
      const pageHeight = window.innerHeight;
      const listHeight = pageHeight - 200;
      listViewStyle.value = {
        'height': `${listHeight}px`
      }
    };
    onMounted(() => {
      window.addEventListener('resize', calcOnResize)
      calcOnResize();
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', calcOnResize)
    });
    return {
      tgtdate,
      slipViewList,
      loading,
      loadDateCnt,
      loadedDateCnt,
      dlgEditIdx,
      wkdate,
      dialogSlip,
      dialogVisible,
      listViewStyle,
      //
      count,
      noMore,
      isDisabled,
      //
      onSlipSubmit,
      onSlipCancel,
      slipedit,
      loadMore,
      reload
    }
  },
  components: {
    SlipInputDlg
  }
});
