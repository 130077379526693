
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from "vue-router";
import { Authenticator, useAuthenticator, translations } from "@aws-amplify/ui-vue";
import { I18n } from 'aws-amplify';
import "@aws-amplify/ui-vue/styles.css";
I18n.putVocabularies(translations);
I18n.setLanguage('ja');

// import awsconfig from '@/aws-exports';
// Amplify.configure(awsconfig);

export default defineComponent({
  name: 'App',
  setup() {
    const router = useRouter();
    const auth = ref(useAuthenticator());
    const activeIndex = ref<string>("1");
    const hideSignup = ref<boolean>(true);
    const menuEllipsis = ref<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onPageSelect = (key: string, keyPath: string[]) => {
      switch(key) {
        case "1":
          router.push({ name: "SlipInput" });
          break;
        case "2":
          router.push({ name: "SlipList" });
          break;
        case "3":
          router.push({ name: "GraphView" });
          break;
        case "4":
          router.push({ name: "ToolsView" });
          break;
        case "5":
          auth.value.signOut();
          break;
      }
    };
    watch(router.currentRoute, (newRoute, oldRoute) => {
      const path = newRoute.path
      switch(path) {
        case "/input":
          activeIndex.value = "1";
          break;
        case "/list":
          activeIndex.value = "2";
          break;
        case "/graph":
          activeIndex.value = "3";
          break;
        case "/tools":
          activeIndex.value = "4";
          break;
        // default:
        //   activeIndex.value = "1";
        //   router.push({ name: "SlipInput" });
      }
    });
    return {
      auth,
      activeIndex,
      hideSignup,
      menuEllipsis,
      //
      onPageSelect
    }
  },
  components: {
    Authenticator
  },
});
