export default {
  Auth: {
    // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'ap-northeast-1:79ce902f-59ed-482e-9b59-707c2a4dfb08',

    // (required)- Amazon Cognito Region
    region: 'ap-northeast-1',

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: 'ap-northeast-1_ul0ys5yvU',

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: '74nj5rktd414cvue3cf32m7gnv',
  }
}